import type { AuctionCar } from '@autobid/ui/types/Car'

export const useCarDescription = () => {
  const { t } = useI18n()

  const DESCRIPTION_EQS = [
    'eq20',
    'eq17',
    'eq174',
    'eq70',
    'eq41',
    'eq144',
    'eq145'
  ]
  const NAME_VALUES = new Set(['eq174', 'eq144', 'eq145'])
  const UNITS = {
    eq20: t('unit.liter')
  }

  const getDescription = (car: AuctionCar) => {
    const eqs = DESCRIPTION_EQS.map((eqId) => {
      const eqData = car.equipments[eqId]

      if (!eqData) return undefined

      if (NAME_VALUES.has(eqId)) {
        return eqData.value ? eqData.name : undefined
      }

      const unit = UNITS[eqId] ? ` ${UNITS[eqId]}` : ''

      return `${eqData.value}${unit}`
    }).filter(Boolean)

    return [car.category.name, ...eqs].join(', ')
  }

  return { getDescription }
}
